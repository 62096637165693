export const past = verb => {
  if (verb.substr(-1) !== 'e') {
    return verb + 'ed'
  }
  return verb + 'd'
}

export const adjective = verb => {
  if (verb.substr(-1) === 'e') {
    return verb.substr(0, verb.length - 1) + 'ing'
  }
  return verb + 'ing'
}
