import getStorage from '../firebase/storage'
import { ref, uploadBytes } from 'firebase/storage'

const uploadFile = async (path, file) => {
  const storage = getStorage()
  const storageRef = ref(storage, path)
  return uploadBytes(storageRef, file)
}

export default uploadFile
