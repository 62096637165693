import getCollection from './getCollection'
import getObject from './getObject'
import updateDataById from 'actions/base/updateDataById'
import uploadFile from 'actions/base/uploadStorage'

const updateGroup = async (id, group) => {
  const photo = group.photo
  if (typeof group.photo === 'object') {
    const path = await uploadFile(`photos/groups/${id}/${photo.path}`, photo)
    console.log(path)
    group.photo = path.metadata.fullPath
  }
  return updateDataById(id, getCollection, getObject(group))
}

export default updateGroup
