import getStorage from '../firebase/storage'
import { ref, getDownloadURL } from 'firebase/storage'

const getStorageDownloadPath = async (path) => {
  const storage = getStorage()
  const storageRef = ref(storage, path)
  return getDownloadURL(storageRef)
}

export default getStorageDownloadPath
