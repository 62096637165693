import React, { forwardRef } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CustomInput from 'components/CustomInput'

export default forwardRef(
  (
    {
      label,
      value,
      error,
      options = [],
      optionValue = 'value',
      optionLabel = 'label',
      variant = 'filled',
      onChange,
      ...props
    },
    ref
  ) => (
    <Autocomplete
      {...props}
      variant={variant}
      ref={ref}
      value={value}
      options={options}
      getOptionLabel={(option) => (option ? option[optionLabel] : '')}
      getOptionSelected={(option, v) =>
        typeof v === 'object' ? v[optionValue] === option[optionValue] : option[optionValue] === v
      }
      onChange={(e, v) => onChange(v)}
      renderInput={(params) => <CustomInput {...params} error={error} label={label} {...props} />}
    />
  )
)
