import yup from 'utils/yup'

const yupHelper = ({ label, name, yup: yupProps }) => {
  let schema = {}
  let validation = yup[yupProps.type]().label(label)

  if (yupProps.type1) {
    validation = validation[yupProps.type1]()
  }

  if (yupProps.required) {
    validation = validation.required()
  }

  name.split('.').reduce((a, b) => {
    schema[a] = yup.object().shape({ [b]: validation })
  })

  return schema
}

export default yupHelper
