import yup from 'utils/yup'
import BaseModel from 'models/BaseModel'
import getStorageDownloadPath from 'actions/base/getStorageDownloadPath'

class GroupModel extends BaseModel {
  constructor(name = '', description = '', photo, isPrivate = false, deletedAt = null, id = null) {
    super()
    this.name = name
    this.description = description
    this.photo = photo
    this.isPrivate = isPrivate
    this.deletedAt = deletedAt
    this.id = id

    getStorageDownloadPath(photo).then((p) => (this.photo = p))
  }

  toReferenceJSON() {
    return {
      name: this.name,
      id: this.id,
    }
  }

  static validationSchema = yup.object({
    name: yup.string().label('Name').required(),
  })
}
export default GroupModel
