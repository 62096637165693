import { defaultStatus } from 'constants/bookings'

const defaults = {
  name: '',
  email: '',
  phone: '',
  start: null,
  end: null,
  status: defaultStatus.value,
}
export default defaults
